import { CompanyRoles, Project, Role, User, UserWithContext } from '@aedifion.io/aedifion-api'
import { computed, ref } from 'vue'
import i18n, { AvailableLocales, getLocale, setLocale } from '@/i18n'
import { defineStore } from 'pinia'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { useUserApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import vuexStore from '@/vuex'

export const useUserStore = defineStore('user', () => {
  const userApiStore = useUserApiStore()

  const loading = ref(false)
  const user = ref<UserWithContext | null>(null)

  async function fetchUser (): Promise<void> {
    loading.value = true
    try {
      const userWithContext = await userApiStore.getUser()
      if (userWithContext.user && !userWithContext.user.locale) {
        userWithContext.user.locale = getLocale()
        await userApiStore.putUser({ user: userWithContext.user })
      }
      user.value = userWithContext
      if (user.value.user) {
        setLocale(user.value.user.locale as AvailableLocales)
      }
    } catch (error) {
      showErrorNotification(i18n.global.t('notifications.errors.user.fetch'))
      reportError(error)
    } finally {
      loading.value = false
    }
  }

  const isRealmAdmin = computed<boolean>(() => user.value?.user?.realm_role === 'admin')

  const currentProject = computed<Project | null>(() => vuexStore.getters['projects/currentProject'])

  const isCompanyAdminOfCurrentProject = computed<boolean>(() => {
    if (isRealmAdmin.value) {
      return true
    }
    if (!currentProject.value) {
      return false
    }
    return user.value?.companyroles?.some((companyRole) => companyRole.company_id === currentProject.value?.company_id && companyRole.name === 'admin') ?? false
  })

  const companyName = computed<string | null>(() => {
    return user.value?.company?.name ?? null
  })

  const companyRoles = computed<CompanyRoles[]>(() => {
    return user.value?.companyroles ?? []
  })

  const condensedUserInformation = computed(() => {
    if (user.value === null) {
      return null
    }

    const fullNameOrEmail = (user.value.user?.firstName && user.value.user?.lastName) ? `${user.value.user.firstName} ${user.value.user.lastName}` : user.value.user?.email ?? ''

    return {
      avatar_url: user.value.user?.avatar_url,
      companyName: companyName.value,
      fullNameOrEmail,
    }
  })

  const projectRoles = computed<Role[]>(() => {
    return user.value?.roles ?? []
  })

  const userDetails = computed<User | null>(() => {
    return user.value?.user ?? null
  })

  return {
    companyName,
    companyRoles,
    condensedUserInformation,
    fetchUser,
    isCompanyAdminOfCurrentProject,
    isRealmAdmin,
    loading,
    projectRoles,
    user,
    userDetails,
  }
})
